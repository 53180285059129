
























































import { Component } from 'vue-property-decorator'
import { SelenaForm } from './SelenaFormWrapper'

@Component({
  name: 'NewTrainingDates'
})
export class NewTrainingDates extends SelenaForm {
  public formData = {
    email: '',
    phoneNumber: ''
  }
}
export default NewTrainingDates
